:root {
    --main-border-radius: 4px;
}

html {
    font-size: 100%;
}

body {
    background-image: url('https://background.eway-crm.com/');
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
}

@media only screen and (max-width: 500px) {
    body {
        background: none;
    }
    .panel {
        min-height: 390px;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
    }
}

@media only screen and (max-height: 450px) {
    .navbar {
        margin-bottom: 0 !important;
    }

    .form {
        min-height: initial !important;
    }
}

@media only screen and (max-height: 430px) {
    .panel-body,
    .panel-heading {
        padding: 10px 24px 10px 24px !important;
    }

    .panel-title {
        margin-top: 10px !important;
    }

    .panel {
        min-height: initial !important;
    }
}

@media only screen and (max-height: 400px) {
    .form-group-content {
        margin-bottom: 10px !important;
    }
}

@media only screen and (max-height: 360px) {
    .panel-brand img,
    .navbar-brand img {
        float: right;
        height: 44px !important;
        margin-bottom: 0.25rem;
    }

    .panel-title {
        font-size: 20px !important;
    }
}

html, body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

a {
    color: #0067b8;
}

a:hover {
    text-decoration: underline;
    color: #666;
}

a:active {
    color: #999;
}

a:focus {
    color: #0067b8;
}

.alert {
    margin: 24px 0 24px 0;
}

.container {
    padding: 0;
    margin: 0;
}

.row {
    margin: 0;
}

.page-login .list-unstyled {
    margin-left: 0;
}

.panel {
    min-height: 390px;
    margin-bottom: 0;
    -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
}

.panel-title {
    font-size: 24px;
    font-weight: 600;
    font-family: "Segoe UI","Helvetica Neue","Lucida Grande","Roboto","Ebrima","Nirmala UI","Gadugi","Segoe Xbox Symbol","Segoe UI Symbol","Meiryo UI","Khmer UI","Tunga","Lao UI","Raavi","Iskoola Pota","Latha","Leelawadee","Microsoft YaHei UI","Microsoft JhengHei UI","Malgun Gothic","Estrangelo Edessa","Microsoft Himalaya","Microsoft New Tai Lue","Microsoft PhagsPa","Microsoft Tai Le","Microsoft Yi Baiti","Mongolian Baiti","MV Boli","Myanmar Text","Cambria Math";
    margin-top: 20px;
    margin-bottom: 0px;
}

.panel-heading {
    padding: 24px 24px 10px 24px;
}

.panel-body {
    padding: 24px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.success-text {
    flex: 0 1 auto;
}

.success-image {
    min-height: 110px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.panel {
    border-radius: var(--main-border-radius);
    border: 0;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    min-height: 338px;
}

.page-error .request-id {
    margin: 0;
    font-weight: normal;
}

@media only screen and (min-width: 440px) {
    .page-error,
    .page-reset,
    .page-doreset,
    .page-login,
    .page-verify,
    .page-loggedOut {
        width: 440px;
    }
}

.page-error,
.page-reset,
.page-doreset,
.page-login,
.page-verify,
.page-loggedOut {
    margin: auto;
}

.panel-default>.panel-heading {
    border: 0;
    background-color: white;
}

.btn-primary {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 123, 255);
    border-color: rgb(0, 123, 255);
    border-radius: var(--main-border-radius);
}

.btn:not(.btn-link) {
    background-color: rgb(0, 103, 184);
    border-radius: var(--main-border-radius);
    min-width: 108px;
    min-height: 32px;
    flex: 0 1 auto;
}

.float-right {
    float: right !important;
}

.form {
    min-height: 210px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.form-group-content {
    margin-bottom: 24px;
    flex: 0 1 auto;
}

.form-group-buttons,
.form-group-buttons-and-links {
    flex: 1 1 auto;
    vertical-align: bottom;
    display: flex;
    flex-direction: column-reverse;
}

.form-group-bottom-text {
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;
}

.form-group-buttons {
    align-items: flex-end;
}

.form-group-bottom-flex {
    display: flex;
    flex-direction: row;
}

.form-bottom-links {
    margin: auto;
    flex: 1 1 auto;
    display: flex;
}

.form-bottom-links li {
    margin: 0 !important;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.passcode {
    border: 0;
    border-bottom: 1px solid rgb(206, 212, 218);
    text-align: center;
    font-size: 20pt;
    width: 40px;
    margin: 0;
    outline: 0;
}

.form-control {
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-control:focus {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-group input[type="text"], .form-group input[type="password"], .form-group input[type="url"], .form-group input[type="email"] {
    padding: 0px !important;
    height: 36px;
    font-size: 15px;
    border-width: 0px 0px 1px;
    border-color: rgba(0, 0, 0, 0.6);
}

.page-login .login-remember {
    padding-left: 0;
}

label {
    font-weight: normal;
}

.navbar {
    width: 100%;
    border-radius: 0;
    min-height: initial !important;
}

.navbar-brand {
    padding: 0 24px 0 24px;
}

.panel-brand img,
.navbar-brand img {
    height: 52px;
}

.resend-code-img {
    width: 19px;
    height: 19px;
    margin-right: 2px;
}